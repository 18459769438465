.hero1{
margin-top: 75px;
}
.courses {
   
    background: linear-gradient(rgba(255, 255, 255, .9), rgba(255, 255, 255, .9)), url(./quaidlogo.png) center center no-repeat;
    background-attachment: fixed;
    background-size: cover;
}
.w-80{
    max-width: 800px;
}
.p0{
    padding: 0 10px;
}
.brd{
border-radius: 25px;
}
.erpimg{
    width: 100%; text-align: center;
}
.imgg{
    width: 100%;height: auto;
}
.imageed:hover{
    cursor: pointer;
    color: white;
    transition: 0.2s;
    transform: scaleX(1.01);
    transform: scaleY(1.01);
}
@media only screen and (max-width: 600px) {

        .h3e{
            margin-bottom: 60px;
        }

}