.page-header {
    background: linear-gradient(rgba(252, 155, 0, 0.911), rgba(255, 225, 148, 0.861)), url(./test2.jpg) center center no-repeat;
    background-size: cover;
}
.popup-container {
    position: fixed;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  .minsl{
border-radius: 15px;
  }
  .acctextjus{
    text-decoration: none;
}
.acctextjus{
  text-align: justify;
}
.Link1{
  text-decoration: none;
}
  @media only screen and (max-width: 600px) {

    .popup-container {
        position: fixed;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 10px;
        width: 100%;
        background-color: #fff;
        border: 1px solid #ccc;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
      }
}
.d-f{
    display: flex;
}
.m-l{
    float: right;
}
.bttnn2{
    display: inline-block;
    outline: 0;
    border: 0;
    cursor: pointer;
    will-change: box-shadow,transform;
    background: radial-gradient( 100% 100% at 100% 0%, #0865fc 0%, #0865fc 100% );
    box-shadow: 0px 2px 4px rgb(45 35 66 / 40%), 0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px rgb(58 65 111 / 50%);
    padding: 0 10px 5px;
    border-radius: 6px;
    color: #fff;
    height:fit-content;
    font-size: 20px;
    text-shadow: 0 1px 0 rgb(0 0 0 / 40%);
    transition: box-shadow 0.15s ease,transform 0.15s ease;
    :hover {
        box-shadow: 0px 4px 8px rgb(255, 255, 255), 0px 7px 13px -3px rgb(255, 255, 255), inset 0px -3px 0px transparent;
        transform: translateY(-2px);
        
    }
    :active{
        box-shadow: inset 0px 3px 7px rgb(255, 255, 255);
        transform: translateY(2px);
}
}
  .bttnn1{
    display: inline-block;
    outline: 0;
    border: 0;
    cursor: pointer;
    will-change: box-shadow,transform;
    background: radial-gradient( 100% 100% at 100% 0%, #ff9900 0%, #ff9900 100% );
    box-shadow: 0px 2px 4px rgb(45 35 66 / 40%), 0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px rgb(58 65 111 / 50%);
    padding: 0 5px 5px;
    border-radius: 6px;
    color: #fff;
    height:fit-content;
    font-size: 20px;
    text-shadow: 0 1px 0 rgb(0 0 0 / 40%);
    transition: box-shadow 0.15s ease,transform 0.15s ease;
    :hover {
        box-shadow: 0px 4px 8px rgb(255, 255, 255), 0px 7px 13px -3px rgb(255, 255, 255), inset 0px -3px 0px transparent;
        transform: translateY(-2px);
        
    }
    :active{
        box-shadow: inset 0px 3px 7px rgb(255, 255, 255);
        transform: translateY(2px);
}
}
