.heading1 {
    text-align: center;
    margin-bottom: 10px;
}
.pp11{
text-align: justify;
}

.heading1 h2 {
    color: #007bff;
}

.formGroup {
    margin-bottom: 5px;
    color: black;
    font-weight: bold;
    font-size: smaller;
}
input,
select,
textarea {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: small;
   }

select {
    appearance: none;
    background: url('data:image/svg+xml;utf8,<svg fill="black" height="24" width="24" xmlns="http://www.w3.org/2000/svg"><polygon points="0,0 24,0 12,24"/></svg>') no-repeat;
    background-position: right 12px top 50%, 0 0;
    background-size: 10px auto, 100%;
    cursor: pointer;
    background-color: #ffffff;
}

textarea {
    resize: vertical;
}

.statusMsg {
    margin-top: 10px;
    font-weight: bold;
} 
.www{
   display: flex;
}
.perag{
    color: #000000;font-size: 19px ;font-weight: bold;
}
.ppii{
    min-height: 450px; border:0;
}
@media only screen and (max-width: 600px)  {
   .www{
       display: contents;
   }
   .col-6 {
       width: 100%;
   }
}
